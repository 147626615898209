import React from "react";
import Context from "./context.js";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FcHighPriority } from "react-icons/fc";

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
        };

    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div style={{ height: '100vh', width: '100%', display: 'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                            <FcHighPriority style={{ fontSize: 60 }} />
                                <div style={{ display: 'flex' ,margin:20}}>
                                    
                                    <h4>لم يتم تثبيت االطلب </h4>
                            
                                </div>
                                <div>
                                <p><b> قد يكون هذا الطلب مكرر او قد حصلت مشكلة ما </b></p>
                                </div>
                                <div style={{  display: 'flex', }}>
                                    <Link to='/From'>
                                        <Button variant="primary" style={{width:100,margin:10,fontWeight:'bold'}}>
                                            طلب جديد
                                        </Button>
                                    </Link>
                                    <Link to='/'>
                                        <Button variant="primary" style={{width:100,margin:10,fontWeight:'bold'}}>
                                            الرئيسيه
                                        </Button>
                                    </Link>

                                </div>
                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;