import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, Textarea, Label, Button } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft, } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { Card, Row, Col, Form } from 'react-bootstrap/';
import { FcMissedCall, FcApproval, FcCancel, FcPaid } from "react-icons/fc";

import * as moment from "moment-timezone";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "delete", name: "الغاء", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "تأكيد", options: { width: 80, filter: true, sort: true, } },


    { field: "more", name: "معلومات", options: { width: 80, filter: true, sort: true, } },
    { field: "whatsapp", name: "واتساب", options: { filter: true, sort: true, } },
    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            data: [],
            count: 0,
            selectedItems: [],
            spinPage: true,
            file1: [],
            file2: [],
            file3: [],
            file4: [],
            file5: [],
            price: '',
            size: 'non',
            notes: ''
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/user/Schedule/order?status=-2`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.orders);
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let color = "";
                    if (res.data.data.orders[i].miss_count === 0) {
                        color = "#fffff"
                    }
                    if (res.data.data.orders[i].miss_count === 1) {
                        color = "#10abca59"
                    }
                    if (res.data.data.orders[i].miss_count === 2) {
                        color = "#105bca59"
                    }
                    if (res.data.data.orders[i].miss_count === 3) {
                        color = "#103fca59"
                    }
                    if (res.data.data.orders[i].miss_count === 4) {
                        color = "#6310ca59"
                    }
                    if (res.data.data.orders[i].miss_count === 5) {
                        color = "#af10ca59"
                    }
                    if (res.data.data.orders[i].miss_count === 6) {
                        color = "#ca104e59"
                    }
                    if (res.data.data.orders[i].miss_count === 7) {
                        color = "#ca661059"
                    }
                    if (res.data.data.orders[i].miss_count === 8) {
                        color = "#ca211059"
                    }
                    if (res.data.data.orders[i].miss_count === 8) {
                        color = "#ca101087"
                    }
                    res.data.data.orders[i].color = color;
                    if (  res.data.data.orders[i].ordersFormItems) {
                      
                        arr.push(res.data.data.orders[i])
                    }
                  
                
                }
              
                this.setState({
                    items: arr, spinPage: false, data: arr, count: arr.length
                });
            })
            .catch(error => {
                console.log(error)
            })
    }


    miss(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.post(host + `users/order/miss/${id}`, { headers: header })
            .then(response => {
                window.alert('تم التحديث')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    deleteSubscriptions(id, name, msg) {

        axios({
            method: 'post',
            url: host + 'users/web/orders/confirm',
            headers: { 'Authorization': cookies.get("token") },
            data: {
                status: 2,
                reject: msg,

                id: id,


            }
        }).then((response) => {
            window.alert(`تم الغاء الطلب رقم ${id} الطلب بأسم ${name}`)

            this.componentDidMount()
        }).catch((error) => {

            window.location.href = '/Error'
        })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    click_log(id, type) {

        axios({
            method: 'post',
            url: host + 'users/web/click/log',
            headers: { 'Authorization': cookies.get("token") },
            data: {
                status: type,
                id: id,
            }
        }).then((response) => {
            // window.alert(`تم الغاء الطلب رقم ${id} الطلب بأسم ${name}`)

            // this.componentDidMount()
        }).catch((error) => {

            window.location.href = '/Error'
        })
        console.log(id, type);
    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>تأكيد المخصص</h4>



                                </div>
                                <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', width: '100%', height: 100, padding: 20 }}>
                                    <h4>  عدد الطلبات : {this.state.count}</h4>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', width: '100%', height: 100, padding: 20 }}>
                                    <h4>  عدد الطلبات : {this.state.count}</h4>
                                </div>
                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                        <Row>
                                            {this.state.data.map((item, index) => (

                                                <Col key={index}>
                                                    <Card style={{ margin: '10px', marginTop: "50px", border: "1px solid #c5320ddb", background: item.color }} >

                                                        <Card.Body>
                                                            <center>

                                                                <Card.Title>رقم الوصل</Card.Title>
                                                                <Card.Title>{item.id}</Card.Title>
                                                            </center>

                                                            <div>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الهاتف</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>واتساب </div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a href={`tel:${item.phone}`} onClick={() => {

                                                                                this.click_log(item.id, 'phone')
                                                                            }}>{item.phone} </a></div></Table.TextCell>
                                                                            <Table.TextCell> <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a onClick={() => {
                                                                                this.click_log(item.id, 'whatssap')
                                                                                setTimeout(() => {
                                                                                    window.location.replace(`https://api.whatsapp.com/send?phone=964${item.phone.slice(1)}`);
                                                                                }, 500);

                                                                            }}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 25 }} /></a></div></Table.TextCell>
                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> اسم الزبون</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم البيج</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر الكلي</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.page_name}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المادة</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>
                                                                        {item.ordersFormItems.map((item, i) =>
                                                                            <Table.Row key={i} >
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.components.name}</div></Table.TextCell>
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.government + " / " + item.city}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>وقت الحجز </div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>تاريخ اخر تحديث</div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >

                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{moment(item.createdAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT')}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{moment(item.updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT')}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                            </div>
                                                            <div style={{ justifyContent: 'center', alignItems: "center", display: "flex", margin: '10px' }}>
                                                                <Component initialState={{ isShown: false, items: [], notes: "", spin: true }}>
                                                                    {({ state, setState }) => (
                                                                        <Pane>
                                                                            <Dialog
                                                                                isShown={state.isShown}
                                                                                onCloseComplete={() => {
                                                                                    setState({ isShown: false })
                                                                                }}
                                                                                confirmLabel="جعله لايرد"
                                                                                intent="Warning"
                                                                                hasFooter={true}
                                                                                title={"تحديد الطلب لايرد"}
                                                                                onConfirm={() => {
                                                                                    axios({
                                                                                        method: 'post',
                                                                                        url: host + 'users/web/orders/confirm',
                                                                                        headers: { 'Authorization': cookies.get("token") },
                                                                                        data: {
                                                                                            status: 3,
                                                                                            id: item.id,
                                                                                        }
                                                                                    }).then((response) => {
                                                                                        window.alert(`تم تحديد كـ لايرد الطلب رقم ${item.id} الطلب بأسم ${item.name}`)
                                                                                        setState({ isShown: false, notes: "" })
                                                                                        this.componentDidMount()
                                                                                    }).catch((error) => {
                                                                                        setState({ isShown: false, notes: "" })
                                                                                        window.location.href = '/Error'
                                                                                    })



                                                                                }}
                                                                            >
                                                                                <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                                                    {!state.spin ?
                                                                                        <div>
                                                                                            <Table>
                                                                                                <Table.Head>
                                                                                                    <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الوصل</div></Table.TextHeaderCell>
                                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم الزبون</div></Table.TextHeaderCell>

                                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>
                                                                                                </Table.Head>
                                                                                                <Table.Body height={80}>

                                                                                                    <Table.Row >
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.id}</div></Table.TextCell>
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.government + " / " + item.city}</div></Table.TextCell>

                                                                                                    </Table.Row>

                                                                                                </Table.Body>
                                                                                            </Table>

                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                                                            <Spinner />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Dialog>
                                                                            <FcMissedCall onClick={() => {
                                                                                setState({ isShown: true, spin: true })
                                                                                axios.get(host + `users/all/ordrers/order/${item.id}`, { headers: { 'Authorization': cookies.get("token") } })
                                                                                    .then(res => {
                                                                                        console.log(res.data.data.order);
                                                                                        setState({ isShown: true, spin: false, items: res.data.data.order })
                                                                                    })
                                                                                    .catch(error => {
                                                                                        console.log(error)
                                                                                    })
                                                                            }} style={{ cursor: 'pointer', fontSize: 25 }} />
                                                                        </Pane>
                                                                    )}
                                                                </Component>


                                                                <Component initialState={{ isShown: false, items: [], notes: "", spin: true }}>
                                                                    {({ state, setState }) => (
                                                                        <Pane>
                                                                            <Dialog
                                                                                isShown={state.isShown}
                                                                                onCloseComplete={() => {
                                                                                    setState({ isShown: false })
                                                                                }}
                                                                                confirmLabel="تأكيد الطلب"
                                                                                intent="success"
                                                                                hasFooter={true}
                                                                                title={"تأكيد الطلب"}
                                                                                onConfirm={() => {


                                                                                    axios({
                                                                                        method: 'post',
                                                                                        url: host + 'users/web/orders/confirm',
                                                                                        headers: { 'Authorization': cookies.get("token") },
                                                                                        data: {
                                                                                            status: 1,

                                                                                            notes: state.notes,
                                                                                            id: item.id,


                                                                                        }
                                                                                    }).then((response) => {
                                                                                        window.alert(`تم تأكيد الطلب رقم ${item.id} الطلب بأسم ${item.name}`)
                                                                                        setState({ isShown: false, notes: "" })
                                                                                        this.componentDidMount()
                                                                                    }).catch((error) => {
                                                                                        setState({ isShown: false, notes: "" })
                                                                                        window.location.href = '/Error'
                                                                                    })



                                                                                }}
                                                                            >
                                                                                <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                                                    {!state.spin ?
                                                                                        <div>
                                                                                            <Table>
                                                                                                <Table.Head>
                                                                                                    <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الوصل</div></Table.TextHeaderCell>
                                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم الزبون</div></Table.TextHeaderCell>

                                                                                                    <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان</div></Table.TextHeaderCell>
                                                                                                </Table.Head>
                                                                                                <Table.Body height={80}>

                                                                                                    <Table.Row >
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.id}</div></Table.TextCell>
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                                                        <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.government + " / " + item.city}</div></Table.TextCell>

                                                                                                    </Table.Row>

                                                                                                </Table.Body>
                                                                                            </Table>
                                                                                            <br></br>
                                                                                            <Label >ملاحظات</Label>
                                                                                            <Textarea id="notes" value={state.notes} name="textarea-1" placeholder="المحلاحظات ان وجدت"
                                                                                                onChange={(e) => {

                                                                                                    setState({ notes: e.target.value })
                                                                                                }} />
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                                                            <Spinner />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Dialog>
                                                                            <FcApproval onClick={() => {
                                                                                setState({ isShown: true, spin: true })
                                                                                axios.get(host + `users/all/ordrers/order/${item.id}`, { headers: { "Content-Type": "application/json", Authorization: cookies.get("token") } })
                                                                                    .then(res => {

                                                                                        setState({ isShown: true, spin: false, items: res.data.data.order })
                                                                                    })
                                                                                    .catch(error => {
                                                                                        console.log(error)
                                                                                    })
                                                                            }} style={item.ordersFormItems[0].components.id !== 68 ? { display: 'flex', cursor: 'pointer', color: '#4a7fc1', fontSize: '30px', margin: '10px' } : { display: "none" }} />
                                                                        </Pane>
                                                                    )}
                                                                </Component>




                                                                {/* <FcApproval style={{ cursor: 'pointer', color: '#4a7fc1', fontSize: '30px', margin: '10px' }} /> */}
                                                                <Component initialState={{ isShown: false, notes: "" }}>
                                                                    {({ state, setState }) => (
                                                                        <Pane>
                                                                            <Dialog
                                                                                isShown={state.isShown}
                                                                                title="الغاء الطلب "
                                                                                intent="danger"
                                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                                confirmLabel="تاكيد"
                                                                                cancelLabel='الغاء'
                                                                                onConfirm={() => {
                                                                                    if (!state.notes) {
                                                                                        window.alert('ادخل سبب الالغاء')
                                                                                        return -1;
                                                                                    } else {
                                                                                        this.deleteSubscriptions(item.id, item.name, state.notes)
                                                                                        setState({ isShown: false, notes: '' })
                                                                                    }


                                                                                }}
                                                                            >
                                                                                <Label dir="rtl">سبب الالغاء</Label>
                                                                                <Textarea dir="rtl" id="notes" value={state.notes} name="textarea-1" placeholder="سبب الالغاء"
                                                                                    onChange={(e) => {

                                                                                        setState({ notes: e.target.value })
                                                                                    }} />


                                                                            </Dialog>
                                                                            <FcCancel style={{ cursor: 'pointer', fontSize: '30px', margin: '10px' }}
                                                                                onClick={() => setState({ isShown: true })} />
                                                                        </Pane>
                                                                    )}
                                                                </Component>


                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>







                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }
                    }
                </Context.Consumer >
            </div >
        );
    }
}

export default Orders;