import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css";
import { Spinner } from 'evergreen-ui';
import host from "./host";
import Cookies from "universal-cookie";
import { Button } from 'react-bootstrap';
import * as moment from "moment";
import Logo from './img/logo.jpg'
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const cookies = new Cookies();


class Orders extends React.Component {
    constructor() {
        super();
        this.state = {
            orders: [],
            orders2: [],
            count: '',
            count2: '',
            spinPage: true,
            ids: [],
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/orders/prints/all`, { headers: header })
            .then(res => {
             
                var arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    if (i % 2 === 1) {
                        arr.push(res.data.data.orders[i].id)
                    }
                }
                this.setState({
                    orders: res.data.data.orders,
                    count: res.data.data.count,
                    ids: arr,
                    spinPage: false
                });
                console.log(arr);
            })
            .catch(error => {
                console.log(error)
            })

        axios.get(host + `users/orders/prints/all`, { headers: header })
            .then(res => {
                console.log(res.data.data);

                this.setState({
                    orders2: res.data.data.orders,
                    count2: res.data.data.count,
                    spinPage: false
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    print() {
        var printContents = document.getElementById('ifmcontentstoprint').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }
    Printing() {
        var Order = this.state.orders;
        var arr = [];
        for (let i = 0; i < Order.length; i++) {
            arr.push(
                <div key={i} style={{ height: 728,padding:'0p 10px' }}>
                    <div id='hederPrint'>

                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }} >

                            <h4> {moment(Order[i].createdAt).format('DD/MM/YYYY')} </h4>
                        </div>
                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }} >

                            <h2> {Order[i].id} </h2>
                        </div>
                        <img id='logoPrint' style={{ width: 140 }} src={Logo} alt='logo' />
                    </div>

                    <div id='linePrint' />
       
                    <div id='hederPrint' style={{ marginTop: 10 }}>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم المرسل</div>
                            <div id='ConTENTList2'>{Order[i].user.name}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم المستلم</div>
                            <div id='ConTENTList2'>{Order[i].name}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> هاتف المرسل</div>
                            <div id='ConTENTList2'>{Order[i].user.phone}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'>هاتف المستلم</div>
                            <div id='ConTENTList2'>{Order[i].phone}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> المبلغ الكلي</div>
                            <div id='ConTENTList2'>{Order[i].price}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'>العنوان</div>
                            <div id='ConTENTList2'>{Order[i].government} / {Order[i].city}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'>المادة</div>
                            <div id='ConTENTList4'>{Order[i].notes2}  </div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'>ملاحظات</div>
                            <div id='ConTENTList4'>{Order[i].notes} </div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'><b>المادة قابلة للكسر</b></div>
                        </div>
                    </div>
                    <div id='linePrint' />
                </div>
            )

        }

        return arr
    }

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        if (!this.state.spinPage) {
                            return (
                                <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Row style={{ margin: 0, width: '100%', direction: 'rtl' }}>
                                        <Col id='CollL' sm={12} md={6}>
                                            <div id='COOON'>
                                                <h4 style={{ color: '#6896dc' }}> الطلبات الجديدة </h4>
                                                <div style={{ display: 'flex', margin: 20 }}>
                                                    <h4> عدد الطلبات </h4>
                                                    <h4> : </h4>
                                                    <h4> {this.state.count} </h4>
                                                </div>
                                                <Button variant="primary" style={{ width: 100, margin: 10 }}
                                                    onClick={() => {

                                                        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
                                                        let formData = new FormData();
                                                        for (let i = 0; i < this.state.ids.length; i++) {
                                                            formData.append("orders_id", this.state.ids[i]);

                                                        }

                                                        axios({ url: host + `users/orders/prints`, method: "POST", data: formData, headers: header })
                                                            .then(response => { console.log('done'); })
                                                            .catch(error => { console.log(error) })

                                                        setTimeout(() => {
                                                            this.print()

                                                        }, 200);
                                                    }}
                                                >
                                                    طباعه
                                                </Button>
                                            </div>
                                        </Col>
                                        {/* <Col id='CollL' sm={12} md={4}>
                                            <div id='COOON'>
                                                <h4 style={{ color: '#6896dc' }}> طلبات تك توك </h4>
                                                <div style={{ display: 'flex', margin: 20 }}>
                                                    <h4> عدد الطلبات </h4>
                                                    <h4> : </h4>
                                                    <h4> {this.state.count} </h4>
                                                </div>
                                                <Button variant="primary" style={{ width: 100, margin: 10 }}
                                                    onClick={() => {

                                                        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
                                                        let formData = new FormData();
                                                        for (let i = 0; i < this.state.ids.length; i++) {
                                                            formData.append("orders_id", this.state.ids[i]);

                                                        }

                                                        axios({ url: host + `users/orders/prints`, method: "POST", data: formData, headers: header })
                                                            .then(response => { console.log('done'); })
                                                            .catch(error => { console.log(error) })

                                                        setTimeout(() => {
                                                            this.print()

                                                        }, 200);
                                                    }}
                                                >
                                                    طباعه
                                                </Button>
                                            </div>
                                        </Col> */}
                                        <Col id='CollL' sm={12} md={6}>
                                            <div id='COOON'>
                                                <h4 style={{ color: '#6896dc' }}> الطلبات السابقة </h4>
                                                <div style={{ display: 'flex', margin: 20 }}>
                                                </div>
                                                <Link to='TableView'>
                                                    <Button variant="primary" style={{ width: 100, margin: 10, fontSize: 20 }} >
                                                        مشاهده
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>

                                    </Row>
                                    <div id='ifmcontentstoprint' style={{ direction: 'rtl' }}>
                                        {this.Printing()}
                                    </div>
                                </div>

                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }

                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;