import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import { Link } from 'react-router-dom';
import host from "./host";
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui'

import { Form, Row, Col, Container, Card, Button, Table, Tabs } from 'react-bootstrap';
import neworder from "./img/neworder.jpeg";

import RE from "./img/re.jpg";
import Lottie from "lottie-react";
import Conf from "./img/conf.json";
import m3 from "./img/m3.json";
import miss from "./img/miss.json";

import newOrder from "./img/newOrder.json";
import search from "./img/search.json";
import watting from "./img/watting.json";
import Out from "./img/out.json";

import Search from "./img/search.png";
import Check from "./img/3.jpg";
import Miss from "./img/m.jpg";
import orders from "./img/orders.jpg";
import items from "./img/items.jpg";
import M from "./img/mm.jpg";
import contact from "./img/contact.png";
import W from "./img/w.jpg";
import Login from './login';
const cookies = new Cookies();
class Home extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            rejected_orders: 0,
            cancel_orders: 0,
            confirmed_orders: 0,
            watting_orders: 0,
            all_orders: 0,
            Percentage: 0,
            role: 0,
        };

    }
    componentDidMount() {
        if (cookies.get("token")) {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/auth/profile`, { headers: header })
                .then((response) => {

                    if (response.data.data.profile.isBan === true) {
                        window.location.href = '/login';
                    }
                    this.setState({ role: response.data.data.profile.role })
                }).catch(() => {
                    window.location.href = '/login';
                })


        } else {
            window.location.href = '/login';

        }

    }



    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        if (cookies.get("token")) {
                            return (
                                <div>
                                    <div className="homeNav">
                                        <Button variant={'danger'} style={{ width: 100 }} onClick={() => {
                                            cookies.remove('token')
                                            window.location.href = '/login';
                                        }}>خروج</Button>

                                        <div>
                                            <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            hasFooter={false}
                                                            title="تقارير الطلبات"
                                                            hasHeader={true}
                                                            intent="success"
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="بحث"
                                                            cancelLabel='الغاء'
                                                            onConfirm={() => {
                                                                let number = document.getElementById('searchValue').value

                                                                window.location.href = `/search?number=${number.replace(/\s/g, '')}`;

                                                            }}
                                                        >
                                                            <div>
                                                                <Table striped bordered hover variant="dark" dir="rtl">

                                                                    <tbody>
                                                                        <tr>

                                                                            <td> الكلي </td>
                                                                            <td>{this.state.all_orders}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td> الانتظار</td>
                                                                            <td>{this.state.watting_orders}</td>


                                                                        </tr>
                                                                        <tr>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الناحج</td>
                                                                            <td>{this.state.confirmed_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الراجع</td>
                                                                            <td>{this.state.rejected_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td> الملغي</td>
                                                                            <td>{this.state.cancel_orders}</td>


                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <div style={{ with: "100%", background: "#1E8449", textAlign: 'center' }}> النسبة المتوقعه: {this.state.Percentage}</div>

                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>

                                                            </div>
                                                        </Dialog>

                                                        <Button onClick={() => setState({ isShown: true })} variant={'success'} style={{ width: 100 }}>معلومات</Button>

                                                        {/* <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                                                        onClick={() => setState({ isShown: true })} /> */}
                                                    </Pane>
                                                )}
                                            </Component>
                                        </div>


                                    </div>
                                    <Container>

                                        <Row style={{ margin: 20 }}>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Lottie animationData={newOrder} loop={true} />

                                                    <Card.Body>
                                                        <Link to='/From'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>انشاء طلب جديد</Button> </center>
                                                        </Link>
                                                    </Card.Body>

                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />


                                        <Row style={{ margin: 20 }}>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Lottie animationData={Out} loop={true} />

                                                    <Card.Body>
                                                        <Link to='/outofstock'>
                                                            <center>   <Button variant="primary" style={{ width: 220, background: "#1F618D" }}> انشاء طلب لمواد غير متوفرة </Button> </center>
                                                        </Link>
                                                    </Card.Body>

                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />

                                        <Row  >
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Lottie animationData={search} loop={true} />

                                                    <Card.Body>
                                                        <Component initialState={{ isShown: false }}>
                                                            {({ state, setState }) => (
                                                                <Pane>
                                                                    <Dialog
                                                                        isShown={state.isShown}
                                                                        title=" ادخل رقم الوصل او رقم الهاتف"
                                                                        intent="success"
                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                        confirmLabel="بحث"
                                                                        cancelLabel='الغاء'
                                                                        onConfirm={() => {
                                                                            let number = document.getElementById('searchValue').value

                                                                            window.location.href = `/search?number=${number.replace(/\s/g, '')}`;

                                                                        }}
                                                                    >
                                                                        <Form.Group className="mb-3" dir="rtl">
                                                                            <Form.Label >رقم الوصل او رقم الهاتف</Form.Label>
                                                                            <Form.Control type="text" id="searchValue" placeholder="رقم الوصل او رقم الهاتف" />

                                                                        </Form.Group>
                                                                    </Dialog>
                                                                    <center> <Button onClick={() => setState({ isShown: true })} variant="primary" style={{ width: 180, background: "#1F618D" }}>بحث عن طلب</Button></center>
                                                                    {/* <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                                                        onClick={() => setState({ isShown: true })} /> */}
                                                                </Pane>
                                                            )}
                                                        </Component>
                                                        {/* <Link to='/search'>
                                                            <center>   <Button variant="primary" style={{ width: 180 }}>بحث عن طلب</Button> </center>
                                                        </Link> */}
                                                    </Card.Body>

                                                </Card>


                                            </Col>

                                        </Row>
                                        {/* <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={RE} />
                                                    <Card.Body>
                                                        <Link to='/rejected'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>الراجع</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br /> */}
                                        <br />
                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Lottie animationData={watting} loop={true} />

                                                    <Card.Body>
                                                        <Link to='/Orders'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>طلبات قيد الانتظار</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br />
                                        <div >

                                            <Row>
                                                <Col md={{ offset: 3 }}>
                                                    <Card style={{ width: '100%' }}>
                                                        <Lottie animationData={m3} loop={true} />

                                                        <Card.Body>
                                                            <Link to='/Support'>
                                                                <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>  معالجة الطلبات</Button> </center>
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>


                                                </Col>

                                            </Row>

                                        </div>
                                        <br />



                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={Check} />
                                                    <Card.Body>
                                                        <Link to='/check'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}> تأكيد الطلبات</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        {/* <br />
                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Lottie animationData={miss} loop={true} />
                                                    <Card.Body>
                                                        <Link to='/Miss_my'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>طلبات لايرد المخصص </Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br /> */}
                                        <br />
                                        <Row>
                                            <Col md={{ offset: 3 }}>
                                                <Card style={{ width: '100%' }}>
                                                    <Card.Img variant="top" src={Miss} />
                                                    <Card.Body>
                                                        <Link to='/Miss'>
                                                            <center>   <Button variant="primary" style={{ width: 180, background: "#1F618D" }}>طلبات لايرد على الهاتف</Button> </center>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                        <br></br>
                                    </Container>
                                </div>
                            )
                        } else {
                            return (
                                <Login />
                            )
                        }

                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Home;