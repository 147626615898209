import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, RadioGroup } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft, FaPhoneAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { BsFillBasketFill } from "react-icons/bs";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { Image } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FcApproval } from "react-icons/fc";

import * as moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    //    { field: "delete", name: "الغاء", options: { width: 80, filter: true, sort: true, } },


    { field: "more", name: "المزيد", options: { width: 80, filter: true, sort: true, } },

    { field: "items", name: "مواد الطلب", options: { width: 80, filter: true, sort: true, } },

    { field: "company", name: "المندوب", options: { width: 80, filter: true, sort: true, } },

    { field: "calls", name: "التبليغات", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "بروفايل", options: { width: 80, filter: true, sort: true, } },
    { field: "photo", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },
    { field: "date", name: "تاريخ الترحيل ", options: { width: 80, filter: true, sort: true, } },

    { field: "status", name: "الحالة", options: { filter: true, sort: true, } },
    { field: "whatsapp", name: "whatsapp", options: { filter: true, sort: true, } },

    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 80, filter: true, sort: true, } },
];
const columns2 = [
    { field: "fix", name: "معالجة", options: { width: 80, filter: true, sort: true, } },

    { field: "more", name: "المزيد", options: { width: 80, filter: true, sort: true, } },

    { field: "items", name: "مواد الطلب", options: { width: 80, filter: true, sort: true, } },

    { field: "call", name: "تبليغ", options: { width: 80, filter: true, sort: true, } },
    { field: "company", name: "المندوب", options: { width: 80, filter: true, sort: true, } },

    { field: "calls", name: "التبليغات", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "بروفايل", options: { width: 80, filter: true, sort: true, } },
    { field: "photo", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },
    { field: "date", name: "تاريخ الترحيل ", options: { width: 80, filter: true, sort: true, } },

    { field: "status", name: "الحالة", options: { filter: true, sort: true, } },
    { field: "whatsapp", name: "whatsapp", options: { filter: true, sort: true, } },

    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 80, filter: true, sort: true, } },
];
let columns3 = []
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    search: false,
    download: false,

}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            spinPage: true,
            role: 0,
            from: new Date(),
            users: []
        };

    }
    handleChangeFrom = date => {

        this.setState({
            from: date
        });
    };
    fixed(id) {
        axios({
            method: 'post',
            url: host + `users/order/fixed/calls/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {
            window.alert('تم معالجة الطلب')

        }).catch((error) => {

        })

    }
    componentDidMount() {
        let users = []
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
        const urlParams = new URLSearchParams(window.location.search);
        const number = urlParams.get('number');
        if (cookies.get("token")) {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
            axios.get(host + `users/auth/profile`, { headers: header })
                .then((response) => {
                    if (response.data.data.profile.role == 3) {



                        columns3 = columns2
                    } else {


                        columns3 = columns

                    }
                    this.setState({ role: response.data.data.profile.role })
                }).catch(() => {
                    window.location.href = '/login';
                })


        } else {
            window.location.href = '/login';

        }

        axios.get(host + `users/calls/users/`, { headers: header })
            .then((response) => {
                users = response.data.users
                this.setState({ users: response.data.users })
            }).catch(() => {
                window.location.href = '/login';
            })



        axios.get(host + `users/orders/search/check?phone=${number}`, { headers: header })
            
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let company = ''
                    let datee = ""
                    console.log(res.data.data.orders);
                    if (res.data.data.orders[i].printerOrders.length > 0) {
                        company = res.data.data.orders[i].printerOrders[0].prints.name
                        datee = moment(res.data.data.orders[i].printerOrders[0].prints.createdAt).format('DD/MM/YYYY')
                    }
                    let newPhone = res.data.data.orders[i].phone.slice(1);
                    let calss = "لا يوجد تبليغ"
                    if (res.data.data.orders[i].calls) {
                        calss = res.data.data.orders[i].calls
                    }
                    let obj = {
                        id: res.data.data.orders[i].id,
                        status: res.data.data.orders[i].status,
                        company: company,
                        date: datee,
                        phone: < a href={`tel:${res.data.data.orders[i].phone}`} >
                            <b>  {res.data.data.orders[i].phone}</b></a>,
                        whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                        more: <Component initialState={{ isShown: false, items: [], spin: true }
                        }>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}
                                        hasFooter={false}
                                        title={res.data.data.orders[i].name}
                                        onConfirm={() => { setState({ isShown: false }) }}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                            {!state.spin ?
                                                <Table>
                                                    <Table.Head>
                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>المجهز</div></Table.TextHeaderCell>
                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>المحافظة</div></Table.TextHeaderCell>
                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                    </Table.Head>
                                                    <Table.Body height={350}>
                                                        <Table.Row key={i} >
                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].user.name}</div></Table.TextCell>
                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].government + " / " + res.data.data.orders[i].city}</div></Table.TextCell>
                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{res.data.data.orders[i].price}</div></Table.TextCell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <FaListUl onClick={() => {
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                </Pane>
                            )
                            }
                        </Component >,

                        call: "",

                        photo: moment(res.data.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY'),
                        calls: calss,

                        items: <Component initialState={{ isShown: false, items: [], spin: true }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}
                                        hasFooter={false}
                                        title={res.data.data.orders[i].name}
                                        onConfirm={() => { setState({ isShown: false }) }}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                            {!state.spin ?
                                                <Table>
                                                    <Table.Head>
                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>الاسم</div></Table.TextHeaderCell>
                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>
                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                    </Table.Head>
                                                    <Table.Body height={350}>
                                                        {state.items.map((item, i) =>
                                                            <Table.Row key={i} >
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <BsFillBasketFill onClick={() => {
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                </Pane>
                            )}
                        </Component>,

                        profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                            onClick={() => window.location.href = res.data.data.orders[i].account} />,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="الغاء الطلب "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel='الغاء'
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleteSubscriptions(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'>هل انت متاكد من عملية الالغاء</span>
                                    </Dialog>
                                    <MdCancel style={{ color: '#d87373de', cursor: 'pointer', fontSize: 20 }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        fix: <Component initialState={{ isShown: false, notes: "" }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title=" معالجة الطلب "
                                        intent="success"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel='الغاء'
                                        onConfirm={() => {
                                            this.fixed(res.data.data.orders[i].id)
                                            window.alert('تم معالجة الطلب')
                                            setState({ isShown: false })
                                        }}
                                    >
                                    </Dialog>
                                    <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,

                    };
                    if (res.data.data.orders[i].is_send==false) {
                        obj.call = 
                        <Component initialState={{ isShown: false, items: [], from: new Date(), spin: true, value: "", msg: "", users: users }
                        }>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => {
                                            setState({ isShown: false })
                                        }}

                                        // hasFooter={false}
                                        title={'تلبيغ'}
                                        onConfirm={() => {

                                            let value = state.value;
                                            let diver = document.getElementById('driverPhone').value
                                            console.log(value);

                                            if (!state.value) {
                                              window.alert('لم يتم تحويل الطلب اختر الشخص للمتابعة')  
                                                return -1
                                            }

                                            axios({
                                                method: 'post',
                                                url: host + 'users/send/call',
                                                headers: { 'Authorization': cookies.get("token") },
                                                data: {
                                                    user_id: state.value,
                                                    driver_phone: diver,
                                                    order_id: res.data.data.orders[i].id
                                                }
                                            }).then((response) => {
                                                window.alert('تم تحويل الطلب ')
                                                setState({ isShown: false })
                                            }).catch((error) => {

                                            })


                                        }}
                                    >
                                        <div style={{ direction: 'rtl' }} >
                                            {!state.spin ?
                                                <div>
                                                    <Form>
                                                        <Form.Label htmlFor="inputPassword5"> اختر الشخص للمتابعة</Form.Label>
                                                        {users.map((item, index) => (

                                                            <div>
                                                                <input type="radio" id={item.id} name="age" value={item.id} onChange={() => {

                                                                    setState({ value: item.id })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>{item.name} </label><br />




                                                            </div>
                                                        ))}
                                                        <Form.Label htmlFor="inputPassword5">رقم المندوب</Form.Label>
                                                        <Form.Control
                                                            width={"75%"}
                                                            type="text"
                                                            id="driverPhone"
                                                            aria-describedby="passwordHelpBlock"
                                                        />
                                                        <input type="text" />

                                                    </Form>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                    <Spinner />
                                                </div>
                                            }
                                        </div>
                                    </Dialog>
                                    <FaPhoneAlt onClick={() => {
                                        console.log('gee');
                                        console.log(state.users);
                                        setState({ isShown: true, spin: true })
                                        axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                            .then(res => {
                                                console.log(res.data.data.order);
                                                setState({ isShown: true, spin: false, items: res.data.data.order })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    }} style={{ cursor: 'pointer', color: '#d61124' }} />
                                </Pane>
                            )
                            }
                        </Component >
                    }else{
                        obj.call = "تم تحويل االطلب"
                    }

                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                })


            })
            .catch(error => {
                console.log(error)
            })
    }
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {
                window.alert('تم الالغاء')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    order_calls() {
        axios({
            method: 'post',
            url: host + 'users/order/calls',
            headers: { 'Authorization': cookies.get("token") },
            data: {


            }
        }).then((response) => {

        }).catch((error) => {

        })
    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>قائمة الطلبات</h4>
                                </div>


                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>



                                            <MaterialDatatable data={this.state.items} columns={columns3} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;