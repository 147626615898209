
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Context from "./context";
import SubmitForm from "./SubmitForm";
import Login from "./login";
import Home from "./home";
import Orders from "./orders";
import Orders2 from "./orders2";
import Items from "./items";
import Done from './done';
import CheckMy from './check_my';
import Miss_my from './missMy';

import PrintTable from './printTable';
import TableView from './TableView';
import Search from './search';

import Search2 from './search2';

import Outs from './outOfStock';


import Order from './orderById';
import Rejected from './rejected';
import Check2 from './check2';
import Edit from './edit';
import Print from './print';
import Error from './error';
import Contact from './Contact';
import './App.css'
import Miss from './Miss';
import Miss2 from './miss2';
import Support from './support';
import PrintNew from './printnew';
import host from './host';
import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();
class App extends Component {
  constructor() {
    super();
    this.state = {

    }

  }
  componentDidMount() {
    // let check = window.location.pathname
    // // let order='https://pages.enfirad.com/Print2/290'
    // // console.log(order.split('/'));
    // if (check.split('/')[3] !== "/login" || check.split('/')[3] !== "/Print2") {
    //   if (cookies.get("token")) {
    //     var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
    //     axios.get(host + `users/auth/profile`, { headers: header })
    //       .then((response) => {
    //         // this.setState({
    //         //     rejected_orders: response.data.data.orders.rejected_orders,
    //         //     cancel_orders: response.data.data.orders.cancel_orders,
    //         //     confirmed_orders: response.data.data.orders.confirmed_orders,
    //         //     watting_orders: response.data.data.orders.watting_orders,
    //         //     all_orders: response.data.data.orders.all_orders,
    //         //     Percentage: response.data.data.orders.confirmed_orders * response.data.data.profile.Percentage
    //         // })
    //         // this.setState({ items: response.data.data.data })
    //       }).catch(() => {
    //        window.location.href = '/login';
    //       })


    //   } else {
    //    window.location.href = '/login';

    //   }
    // }


  }
  render() {
    return (
      <BrowserRouter >
        <Context.Provider
          value={
            {
              value: this.state,
              actions: {
              }
            }
          }
        >

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/From" component={SubmitForm} />

            <Route path="/outofstock" component={Outs} />
            <Route path="/login" component={Login} />
            <Route path="/Orders" component={Orders} />
            <Route path="/Orders2" component={Orders2} />
            <Route path="/Items" component={Items} />
            <Route path="/Done" component={Done} />
            <Route path="/edit" component={Edit} />

            {/* <Route path="/Print" component={PrintTable} /> */}
            <Route path="/TableView" component={TableView} />
            <Route path="/Contact" component={Contact} />
            <Route path="/search" component={Search2} />
            <Route path="/search2" component={Search2} />
            <Route path="/rejected" component={Rejected} />


            <Route path="/error" component={Error} />

            <Route path="/tesst" component={Error} />
            <Route path="/check_my" component={CheckMy} />
            <Route path="/miss_my" component={Miss_my} />
            <Route path="/check" component={Check2} />
            <Route path="/check2" component={Check2} />
            <Route path="/miss" component={Miss2} />
            <Route path="/miss2" component={Miss2} />
            <Route path="/order" component={Order} />
            <Route path="/Print2/:id" component={Print} />
            <Route path="/PrintNew/:id" component={PrintNew} />
            <Route path="/support" component={Support} />

          </Switch>

        </Context.Provider>
      </BrowserRouter>
    );
  }
}

export default App;




