import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, Textarea, Label } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { Tab, Tabs,Row,Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FcApproval, FcRefresh, FcAssistant,FcInfo  } from "react-icons/fc";


import { Link } from 'react-router-dom';
import moment from "moment/moment.js";
const cookies = new Cookies();
const columnsNew = [
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },
    { field: "outDate", name: "تاريخ الترحيل", options: { width: 80, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },

    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const columns = [
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },

    { field: "calls", name: "التبليغ", options: { width: 80, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },

    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const columnsDone = [
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },

    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];

const columnsDel = [
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },

    { field: "date", name: "تاريخ التأجيل", options: { filter: true, sort: true, } },

    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];

const columns2 = [
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },

    { field: "count", name: "عدد المحاولات ", options: { filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const columns3 = [
    
    { field: "info", name: "المزيد", options: { width: 80, filter: true, sort: false, } },
    { field: "update", name: "اخر تحديث", options: { width: 80, filter: true, sort: true, } },

    { field: "phone", name: "رقم الهاتف", options: { width: 80, filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            spinPage: true,
            tab: "new"
        };
        // this.componentDidMount('جديد')
    }
    get_orders(status) {

        if (status == "جديد") {

            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/call/get/my?status=new`, { headers: header })
                .then(res => {
                    let arr = [];
                    for (let i = 0; i < res.data.orders.length; i++) {
               
                        let company = ''
                        // if (res.data.orders[i].printerOrders.length > 0) {
                        //     company = res.data.orders[i].printerOrders[0].prints.name
                        // }
                        let newPhone = res.data.orders[i].phone.slice(1);
                        let obj = {
                            id: res.data.orders[i].id,
                            phone: <a href={`tel:${res.data.orders[i].phone}`}>{res.data.orders[i].phone}</a>
                            ,
                            info: <Link to={`search2?number=${res.data.orders[i].id}`}><FcInfo  style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            calls: res.data.orders[i].calls,
                            driver_phone:res.data.orders[i].price,
                            user:res.data.orders[i].user.name,
                            update: moment(res.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT'),

                            outDate: moment(res.data.orders[i].printerOrders[0].prints.createdAt).format("DD/MM/YYYY"),
                            company: res.data.orders[i].printerOrders[0].prints.name,
                            count: res.data.orders[i].call_count,
                            whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                            address: res.data.orders[i].government + " / " + res.data.orders[i].city,
                            more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            hasFooter={false}
                                            title={res.data.orders[i].name}
                                            onConfirm={() => { setState({ isShown: false }) }}
                                        >
                                            <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                {!state.spin ?
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المجهز</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={350}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FaListUl onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                    </Pane>
                                )}
                            </Component>,
                            change: <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                            }>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            // hasFooter={false}
                                            title={'تلبيغ'}
                                            onConfirm={() => {
                                                let value = state.value;
                                                let date = ""
                                                if (value === 'مؤجل') {
                                                    date = state.from;
                                                    if (!date) {
                                                        window.alert('اختر تاريخ التأجيل')
                                                        return -1
                                                    }

                                                }

                                                axios({
                                                    method: 'post',
                                                    url: host + `users/calss/change/${res.data.orders[i].id}`,
                                                    headers: { 'Authorization': cookies.get("token") },
                                                    data: {
                                                        status: state.value,
                                                        date: date,

                                                    }
                                                }).then((response) => {
                                                    window.alert('تم التبليغ')
                                                    setState({ isShown: false })
                                                    this.componentDidMount("جديد")
                                                    this.get_orders(this.state.tab)
                                                }).catch((error) => {

                                                })


                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }} >
                                                {!state.spin ?
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "لايرد" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "شركة التوصيل" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "خطأ بالتجهيز" })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "كاذب" })
                                                                }} />
                                                                <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                            </div>

                                                            <div>

                                                                <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'flex'
                                                                    setState({ value: "مؤجل" })
                                                                }} />
                                                                <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                            </div>
                                                            <div id="dateDiv" style={{ display: "none" }}>
                                                                <hr></hr>
                                                                <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    id='date1'
                                                                    selected={state.from}
                                                                    onChange={(e) => {
                                                                        setState({ from: e })
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FcRefresh onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                         
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#d61124', fontSize: '20px' }} />
                                    </Pane>
                                )
                                }
                            </Component >,

                            profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                onClick={() => window.location.href = res.data.orders[i].account} />,
                            edit: <Link to={`edit?id=${res.data.orders[i].id}`}><FiEdit style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            add_call:
                             <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="اضافة محاولة "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.add_call(res.data.orders[i].id)
                                                this.componentDidMount("جديد")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcAssistant style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                            delete: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title=" معالجة الطلب "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.fixed(res.data.orders[i].id)
                                                window.alert('تم معالجة الطلب')
                                                this.componentDidMount("جديد")
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                        };
                        arr.push(obj);
                    }
                    this.setState({
                        items: arr, spinPage: false,
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        }
        if (status == "لايرد") {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/call/get/my?status=miss`, { headers: header })
                .then(res => {
                    let arr = [];

                    for (let i = 0; i < res.data.orders.length; i++) {

                        let company = ''
                        // if (res.data.orders[i].printerOrders.length > 0) {
                        //     company = res.data.orders[i].printerOrders[0].prints.name
                        // }
                        let newPhone = res.data.orders[i].phone.slice(1);
                        let obj = {
                            
                            update: moment(res.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT'),
                            id: res.data.orders[i].id,
                            phone: <a href={`tel:${res.data.orders[i].phone}`}>{res.data.orders[i].phone}</a>
                            ,
                            info: <Link to={`search2?number=${res.data.orders[i].id}`}><FcInfo  style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            user:res.data.orders[i].user.name,
                            calls: res.data.orders[i].calls,
                            driver_phone:res.data.orders[i].price,
                            outDate: moment(res.data.orders[i].printerOrders[0].prints.createdAt).format("DD/MM/YYYY"),
                            company: res.data.orders[i].printerOrders[0].prints.name,
                            count: res.data.orders[i].call_count,
                            whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                            address: res.data.orders[i].government + " / " + res.data.orders[i].city,
                            more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            hasFooter={false}
                                            title={res.data.orders[i].name}
                                            onConfirm={() => { setState({ isShown: false }) }}
                                        >
                                            <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                {!state.spin ?
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المجهز</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={350}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FaListUl onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                    </Pane>
                                )}
                            </Component>,
                            change:
                             <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                            }>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            // hasFooter={false}
                                            title={'تلبيغ'}
                                            onConfirm={() => {
                                                let value = state.value;
                                                let date = ""
                                                if (value === 'مؤجل') {
                                                    date = state.from;
                                                    if (!date) {
                                                        window.alert('اختر تاريخ التأجيل')
                                                        return -1
                                                    }

                                                }

                                                axios({
                                                    method: 'post',
                                                    url: host + `users/calss/change/${res.data.orders[i].id}`,
                                                    headers: { 'Authorization': cookies.get("token") },
                                                    data: {
                                                        status: state.value,
                                                        date: date,

                                                    }
                                                }).then((response) => {
                                                    window.alert('تم التبليغ')
                                                    setState({ isShown: false })
                                                    this.get_orders(this.state.tab)
                                                    window.alert('تم معالجة الطلب')
                                                    this.componentDidMount("لايرد")
                                                }).catch((error) => {

                                                })


                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }} >
                                                {!state.spin ?
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "لايرد" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "شركة التوصيل" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "خطأ بالتجهيز" })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "كاذب" })
                                                                }} />
                                                                <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                            </div>

                                                            <div>

                                                                <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'flex'
                                                                    setState({ value: "مؤجل" })
                                                                }} />
                                                                <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                            </div>
                                                            <div id="dateDiv" style={{ display: "none" }}>
                                                                <hr></hr>
                                                                <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    id='date1'
                                                                    selected={state.from}
                                                                    onChange={(e) => {
                                                                        setState({ from: e })
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FcRefresh onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#d61124', fontSize: '20px' }} />
                                    </Pane>
                                )
                                }
                            </Component >,

                            profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                onClick={() => window.location.href = res.data.orders[i].account} />,
                            edit: <Link to={`edit?id=${res.data.orders[i].id}`}><FiEdit style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            add_call: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="اضافة محاولة "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.add_call(res.data.orders[i].id)
                                                this.componentDidMount("لايرد")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcAssistant style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                            delete: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title=" معالجة الطلب "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.fixed(res.data.orders[i].id)
                                                // this.get_orders(this.state.tab)
                                                window.alert('تم معالجة الطلب')
                                                this.componentDidMount("لايرد")
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                        };
                        arr.push(obj);
                    }
                    this.setState({
                        items: arr, spinPage: false
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        }
        if (status == "مؤجل") {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/call/get/my?status=del`, { headers: header })
                .then(res => {
                    let arr = [];

                    for (let i = 0; i < res.data.orders.length; i++) {

                        let company = ''
                        // if (res.data.orders[i].printerOrders.length > 0) {
                        //     company = res.data.orders[i].printerOrders[0].prints.name
                        // }
                        let newPhone = res.data.orders[i].phone.slice(1);
                        let obj = {
                            update: moment(res.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT'),
                            id: res.data.orders[i].id,
                            phone: <a href={`tel:${res.data.orders[i].phone}`}>{res.data.orders[i].phone}</a>
                            ,
                            info: <Link to={`search2?number=${res.data.orders[i].id}`}><FcInfo  style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            user:res.data.orders[i].user.name,
                            date: moment(res.data.orders[i].Delayed).format("DD/MM/YYYY"),
                            calls: res.data.orders[i].calls,
                            driver_phone:res.data.orders[i].price,
                            outDate: moment(res.data.orders[i].printerOrders[0].prints.createdAt).format("DD/MM/YYYY"),
                            company: res.data.orders[i].printerOrders[0].prints.name,
                            count: res.data.orders[i].call_count,
                            whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                            address: res.data.orders[i].government + " / " + res.data.orders[i].city,
                            more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            hasFooter={false}
                                            title={res.data.orders[i].name}
                                            onConfirm={() => { setState({ isShown: false }) }}
                                        >
                                            <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                {!state.spin ?
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المجهز</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={350}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FaListUl onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                    </Pane>
                                )}
                            </Component>,
                            change: <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                            }>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            // hasFooter={false}
                                            title={'تلبيغ'}
                                            onConfirm={() => {
                                                let value = state.value;
                                                let date = ""
                                                if (value === 'مؤجل') {
                                                    date = state.from;
                                                    if (!date) {
                                                        window.alert('اختر تاريخ التأجيل')
                                                        return -1
                                                    }

                                                }

                                                axios({
                                                    method: 'post',
                                                    url: host + `users/calss/change/${res.data.orders[i].id}`,
                                                    headers: { 'Authorization': cookies.get("token") },
                                                    data: {
                                                        status: state.value,
                                                        date: date,

                                                    }
                                                }).then((response) => {
                                                    window.alert('تم التبليغ')
                                                    setState({ isShown: false })
                                                    this.componentDidMount("مؤجل")
                                                    this.get_orders(this.state.tab)
                                                }).catch((error) => {

                                                })


                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }} >
                                                {!state.spin ?
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "لايرد" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "شركة التوصيل" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "خطأ بالتجهيز" })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "كاذب" })
                                                                }} />
                                                                <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                            </div>

                                                            <div>

                                                                <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'flex'
                                                                    setState({ value: "مؤجل" })
                                                                }} />
                                                                <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                            </div>
                                                            <div id="dateDiv" style={{ display: "none" }}>
                                                                <hr></hr>
                                                                <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    id='date1'
                                                                    selected={state.from}
                                                                    onChange={(e) => {
                                                                        setState({ from: e })
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FcRefresh onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#d61124', fontSize: '20px' }} />
                                    </Pane>
                                )
                                }
                            </Component >,

                            profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                onClick={() => window.location.href = res.data.orders[i].account} />,
                            edit: <Link to={`edit?id=${res.data.orders[i].id}`}><FiEdit style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            add_call: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="اضافة محاولة "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.add_call(res.data.orders[i].id)
                                                window.alert('تم معالجة الطلب')
                                                this.componentDidMount("مؤجل")
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcAssistant style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                            delete: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title=" معالجة الطلب "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.fixed(res.data.orders[i].id)
                                                window.alert('تم معالجة الطلب')
                                                this.componentDidMount("مؤجل")
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                        };
                        arr.push(obj);
                    }
                    this.setState({
                        items: arr, spinPage: false
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (status == "اخرى") {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/call/get/my?status=other`, { headers: header })
                .then(res => {
                    let arr = [];

                    for (let i = 0; i < res.data.orders.length; i++) {

                        let company = ''
                        // if (res.data.orders[i].printerOrders.length > 0) {
                        //     company = res.data.orders[i].printerOrders[0].prints.name
                        // }
                        let newPhone = res.data.orders[i].phone.slice(1);
                        let obj = {
                            id: res.data.orders[i].id,
                            phone: <a href={`tel:${res.data.orders[i].phone}`}>{res.data.orders[i].phone}</a>
                            ,
                            update: moment(res.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT'),
                            info: <Link to={`search2?number=${res.data.orders[i].id}`}><FcInfo  style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            user:res.data.orders[i].user.name,
                            calls: res.data.orders[i].calls,
                            driver_phone:res.data.orders[i].price,
                            outDate: moment(res.data.orders[i].printerOrders[0].prints.createdAt).format("DD/MM/YYYY"),
                            company: res.data.orders[i].printerOrders[0].prints.name,
                            count: res.data.orders[i].call_count,
                            whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                            address: res.data.orders[i].government + " / " + res.data.orders[i].city,
                            more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            hasFooter={false}
                                            title={res.data.orders[i].name}
                                            onConfirm={() => { setState({ isShown: false }) }}
                                        >
                                            <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                {!state.spin ?
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المجهز</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={350}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FaListUl onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                    </Pane>
                                )}
                            </Component>,
                            change: <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                            }>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            // hasFooter={false}
                                            title={'تلبيغ'}
                                            onConfirm={() => {
                                                let value = state.value;
                                                let date = ""
                                                if (value === 'مؤجل') {
                                                    date = state.from;
                                                    if (!date) {
                                                        window.alert('اختر تاريخ التأجيل')
                                                        return -1
                                                    }

                                                }

                                                axios({
                                                    method: 'post',
                                                    url: host + `users/calss/change/${res.data.orders[i].id}`,
                                                    headers: { 'Authorization': cookies.get("token") },
                                                    data: {
                                                        status: state.value,
                                                        date: date,

                                                    }
                                                }).then((response) => {
                                                    window.alert('تم التبليغ')
                                                    setState({ isShown: false })
                                                    this.componentDidMount("اخرى")
                                                    this.get_orders(this.state.tab)
                                                }).catch((error) => {

                                                })


                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }} >
                                                {!state.spin ?
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "لايرد" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "شركة التوصيل" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "خطأ بالتجهيز" })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "كاذب" })
                                                                }} />
                                                                <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                            </div>

                                                            <div>

                                                                <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'flex'
                                                                    setState({ value: "مؤجل" })
                                                                }} />
                                                                <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                            </div>
                                                            <div id="dateDiv" style={{ display: "none" }}>
                                                                <hr></hr>
                                                                <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    id='date1'
                                                                    selected={state.from}
                                                                    onChange={(e) => {
                                                                        setState({ from: e })
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FcRefresh onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#d61124', fontSize: '20px' }} />
                                    </Pane>
                                )
                                }
                            </Component >,

                            profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                onClick={() => window.location.href = res.data.orders[i].account} />,
                            edit: <Link to={`edit?id=${res.data.orders[i].id}`}><FiEdit style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            add_call: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="اضافة محاولة "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.add_call(res.data.orders[i].id)
                                                this.componentDidMount("اخرى")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcAssistant style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                            delete: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title=" معالجة الطلب "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.fixed(res.data.orders[i].id)
                                                this.componentDidMount("اخرى")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                        };
                        arr.push(obj);
                    }
                    this.setState({
                        items: arr, spinPage: false
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (status == "done") {
            var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

            axios.get(host + `users/call/get/my?status=done`, { headers: header })
                .then(res => {
                    let arr = [];

                    for (let i = 0; i < res.data.orders.length; i++) {

                        let company = ''
                        // if (res.data.orders[i].printerOrders.length > 0) {
                        //     company = res.data.orders[i].printerOrders[0].prints.name
                        // }
                        let newPhone = res.data.orders[i].phone.slice(1);
                        let obj = {
                            id: res.data.orders[i].id,
                            update: moment(res.data.orders[i].updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT'),
                            phone: <a href={`tel:${res.data.orders[i].phone}`}>{res.data.orders[i].phone}</a>
                            ,
                            info: <Link to={`search2?number=${res.data.orders[i].id}`}><FcInfo  style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            user:res.data.orders[i].user.name,
                            calls: res.data.orders[i].calls,
                            driver_phone:res.data.orders[i].price,
                            outDate: moment(res.data.orders[i].printerOrders[0].prints.createdAt).format("DD/MM/YYYY"),
                            company: res.data.orders[i].printerOrders[0].prints.name,
                            count: res.data.orders[i].call_count,
                            whatsapp: <a href={`https://api.whatsapp.com/send?phone=964${newPhone}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 20 }} /></a>,
                            address: res.data.orders[i].government + " / " + res.data.orders[i].city,
                            more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            hasFooter={false}
                                            title={res.data.orders[i].name}
                                            onConfirm={() => { setState({ isShown: false }) }}
                                        >
                                            <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                {!state.spin ?
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المجهز</div></Table.TextHeaderCell>
                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                            <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>السعر</div></Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={350}>
                                                            {state.items.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.name}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                    <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.price}</div></Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FaListUl onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                                    </Pane>
                                )}
                            </Component>,
                            change: <Component initialState={{ isShown: false, from: new Date(), items: [], spin: true, value: "", msg: "" }
                            }>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            onCloseComplete={() => {
                                                setState({ isShown: false })
                                            }}
                                            // hasFooter={false}
                                            title={'تلبيغ'}
                                            onConfirm={() => {
                                                let value = state.value;
                                                let date = ""
                                                if (value === 'مؤجل') {
                                                    date = state.from;
                                                    if (!date) {
                                                        window.alert('اختر تاريخ التأجيل')
                                                        return -1
                                                    }

                                                }

                                                axios({
                                                    method: 'post',
                                                    url: host + `users/calss/change/${res.data.orders[i].id}`,
                                                    headers: { 'Authorization': cookies.get("token") },
                                                    data: {
                                                        status: state.value,
                                                        date: date,

                                                    }
                                                }).then((response) => {
                                                    window.alert('تم التبليغ')
                                                    setState({ isShown: false })
                                                    this.get_orders(this.state.tab)
                                                }).catch((error) => {

                                                })


                                            }}
                                        >
                                            <div style={{ direction: 'rtl' }} >
                                                {!state.spin ?
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <input type="radio" id="miss" name="age" value="1" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "لايرد" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>لايرد</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="comp" name="age" value="5" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "شركة التوصيل" })
                                                                }} />
                                                                <label for="miss" style={{ margin: 10 }}>شركة التوصيل</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="error" name="age" value="2" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "خطأ بالتجهيز" })
                                                                }} />
                                                                <label for="error" style={{ margin: 10 }}>خطأ بالتجهيز</label><br />
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="la" name="age" value="3" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'none'
                                                                    setState({ value: "كاذب" })
                                                                }} />
                                                                <label for="la" style={{ margin: 10 }}>كاذب</label><br />
                                                            </div>

                                                            <div>

                                                                <input type="radio" id="mo" name="age" value="4" onChange={() => {
                                                                    document.getElementById('dateDiv').style.display = 'flex'
                                                                    setState({ value: "مؤجل" })
                                                                }} />
                                                                <label for="mo" style={{ margin: 10 }}>مؤجل</label><br />
                                                            </div>
                                                            <div id="dateDiv" style={{ display: "none" }}>
                                                                <hr></hr>
                                                                <label for="other" style={{ margin: 10 }}>التاريخ التاجيل</label><br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    id='date1'
                                                                    selected={state.from}
                                                                    onChange={(e) => {
                                                                        setState({ from: e })
                                                                    }}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                        <Spinner />
                                                    </div>
                                                }
                                            </div>
                                        </Dialog>
                                        <FcRefresh onClick={() => {
                                            setState({ isShown: true, spin: true })
                                            axios.get(host + `users/all/ordrers/order/${res.data.orders[i].id}`, { headers: header })
                                                .then(res => {
                                                    console.log(res.data.data.order);
                                                    setState({ isShown: true, spin: false, items: res.data.data.order })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                        }} style={{ cursor: 'pointer', color: '#d61124', fontSize: '20px' }} />
                                    </Pane>
                                )
                                }
                            </Component >,

                            profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }}
                                onClick={() => window.location.href = res.data.orders[i].account} />,
                            edit: <Link to={`edit?id=${res.data.orders[i].id}`}><FiEdit style={{ color: '#007bff', cursor: 'pointer', fontSize: 20 }} /></Link>,
                            add_call: <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="اضافة محاولة "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.add_call(res.data.orders[i].id)
                                                this.componentDidMount("done")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcAssistant style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                            delve:
                             <Component initialState={{ isShown: false, notes: "" }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title=" معالجة الطلب "
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="تاكيد"
                                            cancelLabel='الغاء'
                                            onConfirm={() => {
                                                this.fixed_delv(res.data.orders[i].id)
                                                this.componentDidMount("done")
                                                window.alert('تم معالجة الطلب')
                                                setState({ isShown: false })
                                            }}
                                        >
                                        </Dialog>
                                        <FcApproval style={{ cursor: 'pointer', fontSize: 20 }}
                                            onClick={() => setState({ isShown: true })} />
                                    </Pane>
                                )}
                            </Component>,
                        };
                        arr.push(obj);
                    }
                    this.setState({
                        items: arr, spinPage: false
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }


    componentDidMount(status) {
        if (!status) {
            status = "جديد"
        }
        this.setState({ spinPage: true })
        this.get_orders(status)
    }

    deleteSubscriptions(id, msg) {

        axios({
            method: 'post',
            url: host + `users/all/ordrers/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                msg: msg,
            }
        })
            .then(response => {
                window.alert('تم الالغاء')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    fixed(id) {
        axios({
            method: 'post',
            url: host + `users/order/fixed/calls/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {
            window.alert('تم معالجة الطلب')
            this.get_orders(this.state.tab)
        }).catch((error) => {

        })

    }
    fixed_delv(id) {
        axios({
            method: 'post',
            url: host + `users/order/fixed/delv/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {
            window.alert('تم معالجة الطلب')
            this.get_orders(this.state.tab)
        }).catch((error) => {

        })

    }
    add_call(id) {
        axios({
            method: 'post',
            url: host + `users/order/add/calls/${id}`,
            headers: { 'Authorization': cookies.get("token") },
            data: {
                order_id: id
            }
        }).then((response) => {


            this.componentDidMount("لايرد")
        }).catch((error) => {

        })

    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>قائمة الطلبات</h4>
                                </div>


                                {!this.state.spinPage ?
                                    <Row>
                                        <Col>
                                            <Tabs
                                                defaultActiveKey={this.state.tab}
                                                id="fill-tab-example"
                                                className="mb-6"
                                                onSelect={(k) => {

                                                    if (k === "others") {
                                                        this.setState({ tab: "others" })

                                                        this.componentDidMount('اخرى')
                                                    }
                                                    if (k === "miss") {
                                                        this.setState({ tab: "miss" })
                                                        this.componentDidMount('لايرد')
                                                    }
                                                    if (k === "mojal") {
                                                        this.setState({ tab: "mojal" })
                                                        this.componentDidMount('مؤجل')
                                                    }
                                                    if (k === "new") {
                                                        this.setState({ tab: "new" })
                                                        this.componentDidMount('جديد')
                                                    }
                                                    if (k === "done") {
                                                        this.setState({ tab: "done" })
                                                        this.componentDidMount('done')
                                                    }


                                                }}
                                                fill
                                            >
                                                <Tab eventKey="done" title="تم المعالجة">

                                                    <div className='DataTableContiner'>
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.items} columns={columnsDone} options={options} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="others" title="اخرى">

                                                    <div className='DataTableContiner'>
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="miss" title="لايرد">

                                                    <div className='DataTableContiner'>
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.items} columns={columns2} options={options} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="mojal" title="مؤجل">

                                                    <div className='DataTableContiner'>
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.items} columns={columnsDel} options={options} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="new" title="طلبات جديدة">

                                                    <div className='DataTableContiner'>
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.items} columns={columnsNew} options={options} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </Col>
                                    </Row>

                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;