import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog, Table, Textarea, Label, } from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft, } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { Card, Row, Col, Form, Button, Badge } from 'react-bootstrap/';
import { FcMissedCall, FcApproval, FcCancel, FcPaid } from "react-icons/fc";

import * as moment from "moment-timezone";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "delete", name: "الغاء", options: { width: 80, filter: true, sort: true, } },

    { field: "profile", name: "تأكيد", options: { width: 80, filter: true, sort: true, } },
    { field: "profile", name: "تأكيد", options: { width: 80, filter: true, sort: true, } },


    { field: "more", name: "معلومات", options: { width: 80, filter: true, sort: true, } },
    { field: "whatsapp", name: "واتساب", options: { filter: true, sort: true, } },
    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            data: [],
            selectedItems: [],
            spinPage: true,
            search: '',
            file1: [],
            file2: [],
            file3: [],
            file4: [],
            file5: [],
            price: '',
            size: 'non',
            notes: ''
        };

    
    }
    orders(search) {

        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
        let phone = ""

        if (search) {

            phone = search.replace(/\s/g, '');

        }
        axios.get(host + `users/all/ordrers/my?sort=m&search=${phone}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.orders);
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let color = "";
                    if (res.data.data.orders[i].miss_count === 0) {
                        color = "#fffff"
                    }
                    if (res.data.data.orders[i].miss_count === 1) {
                        color = "#10abca59"
                    }
                    if (res.data.data.orders[i].miss_count === 2) {
                        color = "#105bca59"
                    }
                    if (res.data.data.orders[i].miss_count === 3) {
                        color = "#103fca59"
                    }
                    if (res.data.data.orders[i].miss_count === 4) {
                        color = "#6310ca59"
                    }
                    if (res.data.data.orders[i].miss_count === 5) {
                        color = "#af10ca59"
                    }
                    if (res.data.data.orders[i].miss_count === 6) {
                        color = "#ca104e59"
                    }
                    if (res.data.data.orders[i].miss_count === 7) {
                        color = "#ca661059"
                    }
                    if (res.data.data.orders[i].miss_count === 8) {
                        color = "#ca211059"
                    }
                    if (res.data.data.orders[i].miss_count === 8) {
                        color = "#ca101087"
                    }
                    res.data.data.orders[i].color = color;
                    arr.push(res.data.data.orders[i])
                }

                this.setState({
                    items: arr, spinPage: false, data: arr
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    miss(id) {

        axios({
            method: 'post',
            url: host + `users/order/miss/${id}`,
            headers: { 'Authorization': cookies.get("token") },

        })
            .then(response => {
                window.alert('تم التحديث')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    deleteSubscriptions(id, name, notes) {

        axios({
            method: 'post',
            url: host + 'users/web/orders/confirm',
            headers: { 'Authorization': cookies.get("token") },
            data: {
                status: 2,
                reject: notes,

                id: id,


            }
        }).then((response) => {
            window.alert(`تم الغاء الطلب رقم ${id} الطلب بأسم ${name}`)

            this.componentDidMount()
        }).catch((error) => {

            window.location.href = '/Error'
        })
    }
    recjet2(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `users/orders/rejcted/${id}`, { headers: header })
            .then(response => {

                window.alert('تم ارجاع الطلب')
               // this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                               

                                </div>
                  
                                <div style={{ display: 'flex', justifyContent: "space-around", padding: '15px' }}>
                                    <Button style={{ marginRight: '10px', width: '90px' }} variant="success" onClick={() => {
                                        let serach = document.getElementById('serach').value;
                                        this.orders(serach)
                                    }}>بحث</Button>
                                    <Form.Control
                                        // value={this.state.search}
                                        // onChange={(e) => {
                                        //     console.log(e.target.value);
                                        //     let phone = e.target.value.replace(/\s/g, '');
                                        //     this.setState({ search: phone })

                                        // }}
                                        id="serach"
                                        type="text"
                                    />

                                </div>

                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                        <Row>
                                            {this.state.data.map((item, index) => (

                                                <Col key={index} md={{ span: 8, offset: 2 }}>
                                                    <Card style={{ margin: '0px', marginTop: "50px", border: "1px solid #c5320ddb", background: item.color }} >

                                                        <Card.Body>
                                                            <center>

                                                                <Card.Title>رقم الوصل</Card.Title>
                                                                <Card.Title>{item.id}</Card.Title>
                                                            </center>

                                                            <div>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>رقم الهاتف</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>واتساب </div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العنوان </div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>وقت الحجز </div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>تاريخ اخر تحديث</div></Table.TextHeaderCell>
                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a href={`tel:${item.phone}`}>{item.phone}</a></div></Table.TextCell>
                                                                            <Table.TextCell> <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><a href={`https://api.whatsapp.com/send?phone=964${item.phone.slice(1)}`}> <IoLogoWhatsapp style={{ color: '#28a745', cursor: 'pointer', fontSize: 25 }} /></a></div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.government + " / " + item.city}</div></Table.TextCell>

                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{moment(item.createdAt).tz("Asia/Baghdad").format('DD/MM/YYYY')}</div></Table.TextCell>
                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{moment(item.updatedAt).tz("Asia/Baghdad").format('DD/MM/YYYY')}</div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <b>التبليغ</b> </div></Table.TextHeaderCell>


                                                                    </Table.Head>
                                                                    <Table.Body height={100}>

                                                                        <Table.Row >

                                                                            <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Badge style={{ fontSize: "2em" }} bg="danger"><b>{item.calls}</b></Badge> </div></Table.TextCell>

                                                                        </Table.Row>

                                                                    </Table.Body>
                                                                </Table>
                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>اسم المادة</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>العدد</div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>
                                                                        {item.ordersFormItems.map((item, i) =>
                                                                            <Table.Row key={i} >
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.components.name}</div></Table.TextCell>
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{item.count}</div></Table.TextCell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </Table.Body>
                                                                </Table>

                                                                <Table>
                                                                    <Table.Head>
                                                                        <Table.TextHeaderCell  ><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> الحالة</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>التاريخ</div></Table.TextHeaderCell>
                                                                        <Table.TextHeaderCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>المستخدم</div></Table.TextHeaderCell>

                                                                    </Table.Head>
                                                                    <Table.Body height={100}>
                                                                        {item.log.map((item, i) =>
                                                                            <Table.Row key={i} >
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '10px' }}>{item.status}</div></Table.TextCell>
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '10px' }}>{moment(item.createdAt).tz("Asia/Baghdad").format('DD/MM/YYYY LT')}</div></Table.TextCell>
                                                                                <Table.TextCell><div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '10px' }}>{item.user.name}</div></Table.TextCell>



                                                                            </Table.Row>
                                                                        )}
                                                                    </Table.Body>
                                                                </Table>
                                                            </div>
                                                            <div style={{ justifyContent: 'center', alignItems: "center", display: "flex", margin: '10px' }}>




                                                                {/* <FcApproval style={{ cursor: 'pointer', color: '#4a7fc1', fontSize: '30px', margin: '10px' }} /> */}
                                                                <Component initialState={{ isShown: false, notes: "" }}>
                                                                    {({ state, setState }) => (
                                                                        <Pane>
                                                                            <Dialog
                                                                                isShown={state.isShown}
                                                                                title="ارجاع الطلب "
                                                                                intent="danger"
                                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                                confirmLabel="تاكيد"
                                                                                cancelLabel='الغاء'
                                                                                onConfirm={() => {
                                                                                    this.recjet2(item.id)
                                                                                    setState({ isShown: false, notes: '' })

                                                                                }}
                                                                            >
                                                                                <center><Label dir="rtl" style={{ fontSize: "25px" }}>هل متأكد من ارجاع الطلب ؟ </Label></center>


                                                                            </Dialog>
                                                                            <FcCancel style={{ cursor: 'pointer', fontSize: '30px', margin: '10px' }}
                                                                                onClick={() => setState({ isShown: true })} />
                                                                        </Pane>
                                                                    )}
                                                                </Component>

                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>







                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }
                    }
                </Context.Consumer >
            </div >
        );
    }
}

export default Orders;