import React from "react";
import Context from "../src/context";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css";
import { Spinner } from 'evergreen-ui';
import host from "../src/host.js";
import Cookies from "universal-cookie";
import { Button, Badge } from 'react-bootstrap';
import * as moment from "moment";
import Logo from '../src/img/asw.jpg';
import QRCode from "react-qr-code";
var Barcode = require('react-barcode');

const cookies = new Cookies();


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            count: '',
            spinPage: true,
            name: '',
            phone: "",
            comp: ""
        };

    }

    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }


    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/printed/orders/order/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log('here');
                console.log(res.data.data);
                let data = [];
                this.setState({
                    orders: res.data.data.orders
                    ,
                    count: res.data.data.count,
                    spinPage: false
                });

            })
            .catch(error => {
                console.log(error)
            })


    }
    print() {


        var printContents = document.getElementById('ifmcontentstoprint').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }
    Printing() {
        var Orders = this.state.orders;

        var arr = [];
        for (let key of Object.keys(Orders)) {

            let Order = Orders[key]
            for (let i = 0; i < Order.length; i++) {

                let items = Order[i].notes2.split("/");
                if (i == 0) {
                    arr.push(
                        <div key={i} id="divPrint" style={{ padding: '10px' }}>
                            <div style={{ width: 793.69, height: 549.8,display:"flex",alignItems:"center",justifyContent:"center" }}>
                                <h1>
                                    {key}
                                </h1>
                            </div>


                        </div>


                    )
                }
                arr.push(
                    <div key={i} id='divPrint' style={{ padding: '5px' }}>



                        <div id='hederPrint' style={{ height: 100 }}>

                            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 0 }} >

                                <h6> تاريخ التسليم  </h6>
                                <h6>{moment(new Date()).format('DD/MM/YYYY')}</h6>
                                <img id='logoPrint' style={{ width: 80, height: 'auto' }} src={Logo} alt='logo' />
                            </div>
                            <div>

                                <img id='logoPrint' style={{ width: 100, marginRight: 20 }} src={host + 'files/' + Order[i].logo} alt='logo' />
                            </div>
                            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }} >
                                <center style={{ marginTop: 5 }}>
                                    <Barcode displayValue={false}
                                        height={45} position='relative' width={2} value={Order[i].id} />
                                </center>
                                <h6>  رقم الوصل:{Order[i].id}  </h6>


                            </div>

                        </div>

                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم الصفحة</div>
                            <div id='ConTENTList2'>{Order[i].page_name}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم الزبون</div>
                            <div id='ConTENTList2'>{Order[i].name}</div>
                        </div>
                        <div id='ConTENTList' style={{ display: 'flex', flexDirection: 'row' }}>
                            <div id='ConTENTList' >
                                <div id='ConTENTList1' style={{ width: "100%" }}> هاتف العميل</div>
                                <div id='ConTENTList2' style={{ width: "100%" }}>{Order[i].page_phone}</div>
                            </div>
                            <div id='ConTENTList'>
                                <div id='ConTENTList1' style={{ width: "100%" }}> هاتف الزبون</div>
                                <div id='ConTENTList2' style={{ width: "100%" }}>{Order[i].phone}</div>
                            </div>

                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'>عنوان الزبون</div>
                            <div id='ConTENTList2'>{Order[i].government} / {Order[i].city}</div>
                        </div>
                        <div style={{ display: 'flex', width: "100%" }}>
                            <div style={{ width: '20%' }}>
                                <div id='ConTENTList1'> المبلغ الكلي</div>
                                <div id='ConTENTList4' >
                                    <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                                        {Order[i].price}{Order[i].count_more}
                                    </div>
                                </div>
                            </div>
                            {Order[i].count_more?
                            <div style={{ width: '5%' }}>
                                 <div id='ConTENTList1'> </div>
                                <div style={{height:"100%",backgroundColor:"#000"}}> </div>
                               
                            </div>
                            :null}
                            <div style={Order[i].count_more?{ width: '75%' }:{ width: '80%' }}>
                                <div id='ConTENTList1'> نوع المنتج</div>
                                <div id='ConTENTList4'  >
                                    <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                                        <div>
                                            {items.map((item, i) => <Badge bg="light" style={{ fontSize: "12px", background: "whate", color: "black" }} text="dark">
                                                {item}
                                            </Badge>)}
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>


                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> ملاحظات</div>
                            <div id='ConTENTList2'>{Order[i].notes}</div>
                        </div>






                    </div>
                )

            }
        }


        return arr
    }


    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        if (!this.state.spinPage) {
                            return (
                                <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                    <div id='COOON'>
                                        <h4 style={{ color: '#6896dc' }}> الطلبات </h4>
                                        <div style={{ display: 'flex', margin: 20 }}>


                                            <h4> {this.state.count} </h4>
                                            <h4> : </h4>
                                            <h4> عدد الطلبات </h4>
                                        </div>
                                        <Button variant="primary" style={{ width: 100, margin: 10 }}
                                            onClick={() => {

                                                setTimeout(() => {
                                                    this.print()

                                                }, 200);
                                            }}
                                        >
                                            طباعه
                                        </Button>
                                        <Button variant="success" style={{ width: 100, margin: 10 }} onClick={() => {
                                            window.location.href = `/PrintList`
                                        }}>
                                            رجوع
                                        </Button>
                                    </div>
                                    <div id='ifmcontentstoprint' style={{ direction: 'rtl' }}>
                                        {this.Printing()}
                                    </div>
                                </div>

                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }

                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;