import React from "react";
import Context from "./context.js";
import axios from "axios";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Spinner } from 'evergreen-ui'
import { FaArrowCircleLeft } from "react-icons/fa";
import { MdPrint } from "react-icons/md";
import * as moment from "moment";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "print", name: "طباعه", options: { width: 80, filter: true, sort: true, } },
    { field: "count", name: "عدد الطلبات", options: {  filter: true, sort: true, } },
    { field: "date", name: "تاريخ", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.state = {
            items: [],
            selectedItems: [],
            spinPage: true,
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/printed/orders/all`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.printed);
                for (let i = 0; i < res.data.data.printed.length; i++) {

                    let obj = {
                        id: res.data.data.printed[i].id,
                        date: moment(res.data.data.printed[i].createdAt).format('DD/MM/YYYY'),
                        count: res.data.data.printed[i].count,
                        print: <Link to={`/Print2/${res.data.data.printed[i].id}`}>
                            <MdPrint style={{ color: '#6fd887', cursor: 'pointer', fontSize: 20 }} />
                        </Link>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                console.log(error.response.data)
            })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/Print'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4> قائمة الطلبات السابقه</h4>
                                </div>


                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;