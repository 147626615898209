import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "./host";
import Cookies from "universal-cookie";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog ,Table} from 'evergreen-ui'
import { FaShareSquare, FaListUl, FaArrowCircleLeft } from "react-icons/fa";

import { Link } from 'react-router-dom';
const cookies = new Cookies();
const columns = [
    { field: "more", name: "المزيد", options: { width: 80, filter: true, sort: true, } },
    { field: "profile", name: "بروفايل", options: { width: 80, filter: true, sort: true, } },
    { field: "phone", name: "الهاتف", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            spinPage:true,
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/all/ordrers/my?status=1`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.orders);
                for (let i = 0; i < res.data.data.orders.length; i++) {

                    let obj = {
                        id: res.data.data.orders[i].id,
                        phone: res.data.data.orders[i].phone,
                        more: <Component initialState={{ isShown: false, items: [], spin: true }}>
                        {({ state, setState }) => (
                            <Pane>
                                <Dialog
                                    isShown={state.isShown}
                                    onCloseComplete={() => {
                                        setState({ isShown: false })
                                    }}
                                    hasFooter={false}
                                    title={res.data.data.orders[i].name}
                                    onConfirm={() => { setState({ isShown: false }) }}
                                >
                                    <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                        {!state.spin ?
                                            <Table>
                                                <Table.Head>
                                                    <Table.TextHeaderCell  ><div style={{width:'100%',display:'flex',justifyContent:'center'}}>الاسم</div></Table.TextHeaderCell>
                                                    <Table.TextHeaderCell><div style={{width:'100%',display:'flex',justifyContent:'center'}}>العدد</div></Table.TextHeaderCell>
                                                    <Table.TextHeaderCell><div style={{width:'100%',display:'flex',justifyContent:'center'}}>السعر</div></Table.TextHeaderCell>
                                                </Table.Head>
                                                <Table.Body height={350}>
                                                    {state.items.map((item, i) =>
                                                        <Table.Row key={i} >
                                                            <Table.TextCell><div style={{width:'100%',display:'flex',justifyContent:'center'}}>{item.name}</div></Table.TextCell>
                                                            <Table.TextCell><div style={{width:'100%',display:'flex',justifyContent:'center'}}>{item.count}</div></Table.TextCell>
                                                            <Table.TextCell><div style={{width:'100%',display:'flex',justifyContent:'center'}}>{item.price}</div></Table.TextCell>
                                                        </Table.Row>
                                                    )}
                                                </Table.Body>
                                            </Table>
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', width: '100%' }}>
                                                <Spinner />
                                            </div>
                                        }
                                    </div>
                                </Dialog>
                                <FaListUl onClick={() => {
                                    setState({ isShown: true, spin: true })
                                    axios.get(host + `users/all/ordrers/order/${res.data.data.orders[i].id}`, { headers: header })
                                        .then(res => {
                                            console.log(res.data.data.order);
                                            setState({ isShown: true, spin: false,items:res.data.data.order })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} style={{ cursor: 'pointer', color: '#4a7fc1' }} />
                            </Pane>
                        )}
                    </Component>,
profile: <FaShareSquare style={{ color: '#6fd887', cursor: 'pointer',fontSize:20 }}
                            onClick={() => window.location.href = res.data.data.orders[i].account} />,
                         };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ expire: true })
                }
                console.log(error.response.data)
            })
    }
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {
                window.alert('تم الالغاء')
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',height:100,padding:20}}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>قائمة الطلبات</h4>
                                </div>


                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;